<template>
  <v-container>
    <v-row md="6" offset-md="0" sm="10" offset-sm="1">
      <v-col>
        <v-card>
          <v-row>
            <v-col cols="12">
              <h1 style="text-align: center">
                Current Points: {{ current_points }}
              </h1>
              <div style="text-align: center">
                Average Points over Time: <br />
                90 Day: {{ ninety_points }} ❚ 60 Day: {{ sixty_points }} ❚ 30
                Day: {{ thirty_points }} <br />
                <span
                  v-if="current_points < 0"
                  title="Based on last average points earned over the last 90 days"
                >
                  Projected Date of Project Restart: {{ projected_payout_date }}
                </span>
              </div>
            </v-col>
            <v-col cols="12">
              <line-chart :chart_data="this.displayed_items"></line-chart>
            </v-col>
          </v-row>
          <br />
          <v-data-table
            :headers="headers"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :items="displayed_items"
            :items-per-page="20"
            :footer-props="{
              itemsPerPageOptions: [20, 40, -1],
            }"
            class="elevation-3"
          >
            <!-- eslint-disable-next-line -->
            <template v-slot:item.event_date="{ item }">
              {{ formatDate(item.event_date) }}
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <br />
  </v-container>
</template>

<script>
import LineChart from "@/components/LineChart";
import dayjs from "dayjs";

export default {
  components: { LineChart },

  async mounted() {
    // for (let index = 0; index < this.$store.state.points.length; index++) {
    //   if (this.$store.state.points[index]?.allocation == null) {
    //     this.$store.state.points[index].allocation = "ATAA";
    //   }
    // }

    this.local_pnts = this.$store.state.points;

    this.current_points = this.totalPoints(this.local_pnts);
    this.local_pnts.forEach((element) => {
      this.displayed_items.push({
        ...element,
        event_date: element.event_date.seconds * 1000,
        event_date_iso: dayjs(element.event_date.seconds * 1000).format(),
      });
    });
    this.ninety_points = this.periodDayPoints(90);
    this.sixty_points = this.periodDayPoints(60);
    this.thirty_points = this.periodDayPoints(30);
    let avg_pnts =
      (this.ninety_points + this.sixty_points + this.thirty_points) / 3;
    this.projected_payout_date = this.formatDate(
      dayjs().add(-this.current_points / avg_pnts, "day")
    );
  },
  methods: {
    formatDate(date) {
      return dayjs(date).format("M-DD-YY");
    },
    totalPoints: function (pnts) {
      if (pnts.length === 0) return 0;
      return pnts
        .map((item) => item.points)
        .reduce((prev, next) => prev + next);
    },
    periodDayPoints: function (period) {
      if (this.local_pnts.length === 0) return 0;
      const periodDaysAgo = dayjs().subtract(period, "day");
      let pnts =
        this.local_pnts
          .map((item) =>
            item.points < 0 ||
            dayjs(item.event_date.seconds * 1000) < periodDaysAgo
              ? 0
              : item.points
          )
          .reduce((prev, next) => prev + next) / period;
      if (pnts < 10) {
        return Math.round(pnts * 100) / 100;
      }
      return Math.round(pnts);
    },
  },
  data() {
    return {
      local_pnts: [],
      sortBy: "event_date",
      sortDesc: true,
      displayed_items: [],
      current_points: 0,
      ninety_points: 0,
      sixty_points: 0,
      thirty_points: 0,
      projected_payout_date: "",
      headers: [
        { text: "Date", value: "event_date", align: "start" },
        { text: "Points", value: "points" },
        { text: "Description", value: "description", sortable: false },
      ],
    };
  },
};
</script>
