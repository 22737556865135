<template>
  <canvas id="core_values_chart" width="400" height="150"></canvas>
</template>

<style scoped></style>

<script>
import Chart from "chart.js";
import dayjs from "dayjs";

export default {
  name: "CoreValueBarChart",
  props: ["chart_data"],
  watch: {
    chart_data: function () {
      this.handleChart();
    },
  },
  data: () => ({
    myLineChart: null,
  }),
  mounted() {
    this.$nextTick(function () {
      // Sometimes the first call doesn't have any data yet
      if (this.chart_data.remaining !== null) {
        this.handleChart();
      }
    });
  },
  methods: {
    numDaysBetween(d1, d2) {
      var diff = Math.abs(d1.getTime() - d2.getTime());
      return diff / (1000 * 60 * 60 * 24);
    },
    generateChartData() {
      let tmp_line = [];
      let first = true;
      let csum = 0;

      for (let element of this.chart_data) {
        if (first) {
          first = false;
          csum = element.points;
          tmp_line.push({
            t: dayjs(element.event_date_iso).format("YYYY-MM-DD"),
            y: csum,
          });
        } else {
          if (
            tmp_line[tmp_line.length - 1].t ===
            dayjs(element.event_date_iso).format("YYYY-MM-DD")
          ) {
            csum += element.points;
            tmp_line[tmp_line.length - 1].y = csum;
          } else {
            csum += element.points;

            tmp_line.push({
              t: dayjs(element.event_date_iso).format("YYYY-MM-DD"),
              y: csum,
            });
          }
        }
      }

      let temp_line_2 = [];
      for (let i = 0; i < 90; i++) {
        const date = dayjs().subtract(i, "day").format("YYYY-MM-DD");
        temp_line_2.push({
          t: date,
          y: 0,
        });
      }
      temp_line_2.reverse();
      for (let i = 0; i < temp_line_2.length; i++) {
        // find date in tmp_line
        let date = temp_line_2[i].t;
        let index = tmp_line.findIndex((e) => {
          return e.t === date;
        });
        let y_val = null;
        if (index !== -1) {
          y_val = tmp_line[index].y;
        } else {
          if (i === 0) {
            // find first date in tmp_line closest to date
            y_val = 0;
            for (let j = 1; j < 20; j++) {
              // check every day going back 20 days
              let date_2 = dayjs(date).subtract(j, "day").format("YYYY-MM-DD");
              let index_2 = tmp_line.findIndex((e) => {
                return e.t === date_2;
              });
              if (index_2 !== -1) {
                y_val = tmp_line[index_2].y;
                break;
              }
            }
          } else {
            y_val = temp_line_2[i - 1].y;
          }
        }

        temp_line_2[i] = {
          t: date,
          y: y_val,
        };
      }
      return temp_line_2;
    },
    handleChart() {
      let ataa_data = this.generateChartData();

      if (this.myLineChart) this.myLineChart.destroy();
      this.myLineChart = new Chart(
        document.getElementById("core_values_chart"),
        {
          type: "line",

          data: {
            labels: ataa_data.map(function (i) {
              return i.t;
            }),
            datasets: [
              {
                label: "Points over Time",
                lineTension: 0.1,
                data: ataa_data,
                borderColor: "rgb(33, 150, 243)",
                backgroundColor: "rgba(33, 150, 243, 0.03)",
                borderWidth: 1,
                pointRadius: 0.25,
                pointHoverRadius: 1,
                pointHitRadius: 5,
              },
            ],
          },
          options: {
            legend: {
              display: false,
            },
            scales: {
              xAxes: [
                {
                  type: "time",
                  distribution: "linear",
                },
              ],
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                  },
                },
              ],
            },
          },
        }
      );
    },
  },
};
</script>
