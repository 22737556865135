<template>
  <v-container>
    <points-page
      v-if="$store.state.points.length > 0 && tab === 0"
    ></points-page>
  </v-container>
</template>

<script>
import PointsPage from "@/components/PointsPage";
export default {
  name: "PointsView",
  components: { PointsPage },
  data() {
    return { tab: 0 };
  },
  async mounted() {
    await this.getTable("points", "event_date");
  },
};
</script>
