<template>
  <v-card>
    <v-img
      src="https://images.pexels.com/photos/3653997/pexels-photo-3653997.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
      class="white--text align-end"
      gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
      height="200px"
    >
      <v-card-title>Special one-off requests/challenges</v-card-title>
    </v-img>

    <v-card-text>
      <p>
        Rules: Only one per day! So make it count. If time requirement isn't
        met, then you get a percent based on how close you were. If min time was
        2 min at 100 points, and you did 1 min, you'd get 50 points
      </p>

      <v-tabs
        v-model="tab"
        next-icon="mdi-arrow-right-bold-box-outline"
        prev-icon="mdi-arrow-left-bold-box-outline"
        show-arrows
      >
        <v-tabs-slider></v-tabs-slider>

        <v-tab v-for="item in items" :key="item">
          {{ item }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item v-for="item in items" :key="item">
          <v-card flat>
            <v-card-text>
              <v-data-table
                :headers="headers"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :items="subset_one_off"
                :items-per-page="200"
                :hide-default-footer="true"
                :footer-props="{
                  itemsPerPageOptions: [-1],
                }"
              >
                <template #[`item.challenge_txt`]="{ item }">
                  <br />
                  <div
                    :class="{ warn_org: item.important }"
                    v-html="item.challenge_txt"
                  ></div>
                  <br />
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<style>
.v-data-table-header th {
  white-space: nowrap;
}
</style>

<style scoped>
.v-data-table-header th {
  white-space: nowrap;
}

.v-card__text {
  text-decoration: inherit;
  /* text-decoration: line-through !important;
  text-decoration-color: white !important;
  text-decoration-thickness: 40% !important; */
}

.theme--dark .v-data-table {
  color: rgba(255, 255, 255, 0.7) !important;
}

.theme--dark .v-data-table-header {
  color: rgba(255, 255, 255, 0.7) !important;
}

.theme--dark .v-data-table td {
  color: rgba(255, 255, 255, 0.7) !important;
}

.mobile_pad {
  margin-right: 15px;
}

.v-data-table td {
  width: 50%;
  padding: 10px !important;
  padding-left: 16px !important;
}
.v-data-table td:nth-child(2) {
  text-align: center;
}

.warn_org {
  background-color: rgba(255, 165, 0, 1);
}
.warn_org:hover {
  background-color: rgba(255, 165, 0, 0.5);
}
.theme--dark .warn_org {
  background-color: rgb(101, 65, 0);
}
</style>

<script>
export default {
  name: "SpecialRequests",

  async mounted() {
    await this.getTable("one_off", "order");
    this.items = [];
    this.$store.state.one_off.forEach((element) => {
      if (!this.items.includes(element.tag)) {
        this.items.push(element.tag);
      }
    });
    this.items = this.items.sort();
    this.$nextTick(function () {
      this.tab = 0;
    });
  },

  data: () => ({
    tab: 0,
    items: ["Loading..."],
    sortBy: "point_value",
    sortDesc: false,
    headers: [
      { text: "Challenges", value: "challenge_txt", align: "start" },
      { text: "Point Value", value: "point_value", align: "start" },
      { text: "Min Time", value: "min_req", align: "start" },
    ],
  }),
  computed: {
    subset_one_off() {
      return this.$store.state.one_off.filter(
        (item) => item.tag == this.items[this.tab]
      );
    },
  },
};
</script>
