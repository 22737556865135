<template>
  <v-container>
    <v-row>
      <v-col>
        <general-requests></general-requests>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <special-requests></special-requests>
      </v-col>
    </v-row>
    <br /><br /><br />
  </v-container>
</template>

<script>
// @ is an alias to /src
import SpecialRequests from "@/components/SpecialRequests.vue";
import GeneralRequests from "@/components/GeneralRequests.vue";
// import PublicMiniGame from "@/components/PublicMiniGame.vue";

export default {
  name: "HomeView",
  components: {
    GeneralRequests,
    SpecialRequests,
    // PublicMiniGame,
  },
};
</script>
