<template>
  <div class="admin">
    <div v-if="!$store.state.user">
      <p>Please Sign In</p>
    </div>

    <v-row v-else>
      <v-col cols="10" offset="1" md="5" offset-md="1">
        <v-card elevation="2">
          <v-card-title>One Off</v-card-title>
          <v-card-text>
            <!-- one off -->
            <v-textarea
              v-model="challenge_txt"
              label="Challenge Text"
            ></v-textarea>
            <v-text-field v-model="tag" label="Tag"></v-text-field>
            <v-select
              :items="['True', 'False']"
              label="Important"
              v-model="important"
            ></v-select>
            <v-text-field
              v-model="order"
              label="Order"
              type="number"
            ></v-text-field>
            <v-text-field
              v-model="point_value"
              label="Point Value"
            ></v-text-field>
            <v-text-field v-model="min_req" label="Min Time"></v-text-field>
          </v-card-text>

          <v-card-actions>
            <v-btn color="primary" @click="oneoff_submit()" elevation="2"
              >Submit</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col cols="10" offset="1" md="5" offset-md="0">
        <v-card elevation="2">
          <v-card-title>Points</v-card-title>
          <v-card-text>
            <!-- points -->
            <v-text-field
              v-model="description"
              label="Description"
            ></v-text-field>
            <v-text-field
              v-model="points"
              label="Points"
              type="number"
            ></v-text-field>
            <v-select
              v-model="allocation"
              :items="['WECAN', 'ATAA']"
              label="Allocation"
            ></v-select>
            <v-menu
              v-model="fromDateMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  label="Date"
                  readonly
                  :value="fromDateDisp"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="fromDateVal"
                @input="fromDateMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-card-text>

          <v-card-actions>
            <v-btn color="primary" @click="points_submit()" elevation="2"
              >Submit</v-btn
            >
          </v-card-actions>
        </v-card>
        <v-card elevation="2" style="margin-top: 20px">
          <v-card-title>Time in Minutes to Points</v-card-title>
          <v-card-text>
            <!-- points -->
            <v-text-field
              v-model="time_h"
              label="Time"
              type="number"
            ></v-text-field>
            <div>
              Points: <span>{{ calc_points }}</span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <br /><br /><br />
  </div>
</template>

<script>
// @ is an alias to /src
// import Special from "@/components/Special.vue";
// import General from "@/components/General.vue";
// import PublicMiniGame from "@/components/PublicMiniGame.vue";

export default {
  name: "AdminView",
  components: {
    // General,
    // Special,
    // PublicMiniGame,
  },

  methods: {
    oneoff_submit() {
      this.$db
        .collection("one_off")
        .add({
          challenge_txt: this.challenge_txt,
          important: this.important === "True",
          order: +this.order,
          point_value: this.point_value,
          min_req: this.min_req,
          tag: this.tag,
        })
        .then(() => {
          //   console.log("Document written with ID: ", docRef.id);
          this.challenge_txt = "";
          this.tag = "";
          this.order = 10;
          this.point_value = "";
          this.important = "False";
          alert("Success");
        })
        .catch((error) => {
          alert(error);
        });
    },
    points_submit() {
      let ending = " 00:00:00 MST";
      if (navigator.userAgent.toLowerCase().indexOf("firefox") > -1) {
        ending = " 00:00:00";
      }

      this.$db
        .collection("points")
        .add({
          description: this.description,
          event_date: new Date(this.fromDateVal + ending),
          points: +this.points,
          allocation: this.allocation,
        })
        .then(() => {
          this.description = "";
          this.points = 0;
          this.fromDateVal =
            new Date().getFullYear() +
            "-" +
            (new Date().getMonth() + 1 + "").padStart(2, "0") +
            "-" +
            new Date().getDate();
          alert("Success");
        })
        .catch((error) => {
          alert(error);
        });
    },
  },

  data() {
    return {
      challenge_txt: "",
      important: "False",
      order: 10,
      point_value: "",
      min_req: "",
      tag: "",
      time_h: null,
      description: "",
      allocation: "WECAN",
      points: 0,
      fromDateMenu: false,
      fromDateVal:
        new Date().getFullYear() +
        "-" +
        (new Date().getMonth() + 1 + "").padStart(2, "0") +
        "-" +
        new Date().getDate(),
    };
  },
  computed: {
    fromDateDisp() {
      return this.fromDateVal;
      // format date, apply validations, etc. Example below.
      // return this.fromDateVal ? this.formatDate(this.fromDateVal) : "";
    },
    calc_points() {
      if (this.time_h === "" || this.time_h === null) {
        return "Unknown";
      }
      return Math.round(((100 * this.time_h) / 60) * 100) / 100;
    },
  },
};
</script>
