var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[(!_vm.$store.state.loading)?_c('v-app-bar',{attrs:{"app":"","color":"primary","dark":"","clipped-right":""}},[_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"mr-2"},[_vm._v("Website Challenges")])]),_c('v-spacer'),(_vm.$vuetify.breakpoint.xsOnly)?_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}}):_vm._e(),(!_vm.$vuetify.breakpoint.xsOnly)?_c('v-btn',{attrs:{"to":"/","text":""}},[_c('span',{staticClass:"mr-2"},[_vm._v("Home")]),_c('v-icon',[_vm._v("mdi-home")])],1):_vm._e(),(!_vm.$vuetify.breakpoint.xsOnly)?_c('v-btn',{attrs:{"to":"/points","text":""}},[_c('span',{staticClass:"mr-2"},[_vm._v("Points")]),_c('v-icon',[_vm._v("mdi-table-check")])],1):_vm._e(),(
        !_vm.$vuetify.breakpoint.xsOnly &&
        _vm.$store.state.user &&
        _vm.$store.state.user.uid == '6P9vH41jkINrqfe6kGfxRj8tpUu1'
      )?_c('v-btn',{attrs:{"to":"/admin","text":""}},[_c('span',{staticClass:"mr-2"},[_vm._v("Admin")]),_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e(),(!_vm.$vuetify.breakpoint.xsOnly && _vm.$store.state.user)?_c('v-btn',{attrs:{"text":""},on:{"click":_vm.signout}},[_c('span',{staticClass:"mr-2"},[_vm._v("Sign Out")]),_c('v-icon',[_vm._v("mdi-logout")])],1):_vm._e(),(!_vm.$vuetify.breakpoint.xsOnly && !_vm.$store.state.user)?_c('v-btn',{attrs:{"text":""},on:{"click":_vm.googleSignIn}},[_c('span',{staticClass:"mr-2"},[_vm._v("Sign In")]),_c('v-icon',[_vm._v("mdi-login")])],1):_vm._e()],1):_vm._e(),(!_vm.$store.state.loading)?_c('v-navigation-drawer',{attrs:{"fixed":"","temporary":"","dark":"","right":"","clipped":"","src":"https://cdn.vuetifyjs.com/images/backgrounds/bg-2.jpg","width":"50%"},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',{attrs:{"nav":"","dense":""}},[_c('v-list-item-group',{attrs:{"active-class":"text--accent-4"},model:{value:(_vm.group),callback:function ($$v) {_vm.group=$$v},expression:"group"}},[_c('v-list-item',{attrs:{"link":"","to":"/"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-home")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("HOME")])],1)],1),_c('v-list-item',{attrs:{"link":"","to":"/points"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-table-check")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("POINTS")])],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.themeSwitch()}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-brightness-4")])],1),_c('v-list-item-content',[(_vm.$vuetify.theme.dark)?_c('v-list-item-title',[_vm._v("LIGHT MODE")]):_vm._e(),(!_vm.$vuetify.theme.dark)?_c('v-list-item-title',[_vm._v("DARK MODE")]):_vm._e()],1)],1),(
            _vm.$store.state.user &&
            _vm.$store.state.user.uid == '6P9vH41jkINrqfe6kGfxRj8tpUu1'
          )?_c('v-list-item',{attrs:{"link":"","to":"/admin"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-plus")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("ADMIN")])],1)],1):_vm._e(),(_vm.$store.state.user)?_c('v-list-item',{attrs:{"link":""},on:{"click":_vm.signout}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-logout")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("SIGN OUT")])],1)],1):_vm._e(),(!_vm.$store.state.user)?_c('v-list-item',{attrs:{"link":""},on:{"click":_vm.googleSignIn}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-login")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("SIGN IN")])],1)],1):_vm._e()],1)],1)],1):_vm._e(),_c('v-main',[(!_vm.$vuetify.breakpoint.xsOnly)?_c('br'):_vm._e(),(_vm.$store.state.loading)?_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"span":12}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary","size":200,"width":7}})],1)],1):_c('router-view')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }