<template>
  <v-card>
    <v-img
      src="https://images.pexels.com/photos/2457317/pexels-photo-2457317.png?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
      class="white--text align-end"
      gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
      height="200px"
    >
      <v-card-title>General</v-card-title>
    </v-img>

    <v-card-text>
      <h2 class="text-center">Point Conversion</h2>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Conversion</th>
              <th class="text-center">Item</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in $store.state.conversion" :key="item.id">
              <td>{{ item.conversion }}</td>
              <td>{{ item.item }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <br />
      <h2 class="text-center strike">Repeatable Events</h2>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left strike">General</th>
              <th class="text-center strike">Point Value</th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="strike"
              v-for="item in $store.state.general"
              :key="item.id"
            >
              <td>{{ item.general }}</td>
              <td>{{ item.point_value }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  components: {},
  name: "GeneralRequests",

  mounted() {
    this.getTable("conversion", "order");
    this.getTable("general", "order");
  },

  data: () => ({}),
};
</script>
<style scoped>
.strike {
  text-decoration: inherit;
  /* text-decoration: line-through;
  text-decoration-color: white !important;
  text-decoration-thickness: 40% !important; */
}

.theme--dark .v-data-table td {
  color: rgba(255, 255, 255, 0.7) !important;
}
.mobile_pad {
  margin-left: 15px;
}

.v-data-table td {
  width: 50%;
  padding: 10px !important;
  padding-left: 16px !important;
}
.v-data-table td:nth-child(2) {
  text-align: center;
}
</style>
