import { render, staticRenderFns } from "./SpecialRequests.vue?vue&type=template&id=2d454e86&scoped=true&"
import script from "./SpecialRequests.vue?vue&type=script&lang=js&"
export * from "./SpecialRequests.vue?vue&type=script&lang=js&"
import style0 from "./SpecialRequests.vue?vue&type=style&index=0&lang=css&"
import style1 from "./SpecialRequests.vue?vue&type=style&index=1&id=2d454e86&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d454e86",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VImg } from 'vuetify/lib/components/VImg';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';
installComponents(component, {VCard,VCardText,VCardTitle,VDataTable,VImg,VTab,VTabItem,VTabs,VTabsItems,VTabsSlider})
