import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

export default function firebaseInit() {
  const firebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyBWaFn_oLR4LrTEHs6YAi-Mi5IPTZCLBjs",
    authDomain: "auth.mattharris.tech",
    projectId: "website-challenges-26ef9",
    storageBucket: "website-challenges-26ef9.appspot.com",
    messagingSenderId: "653276837984",
    appId: "1:653276837984:web:8e3c0593c6482b2fadab05",
    measurementId: "G-BWG4CR7MKV",
  });

  return firebaseApp.firestore();
}
