<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
      clipped-right
      v-if="!$store.state.loading"
    >
      <div class="d-flex align-center">
        <span class="mr-2">Website Challenges</span>
      </div>

      <v-spacer></v-spacer>
      <v-app-bar-nav-icon
        v-if="$vuetify.breakpoint.xsOnly"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>

      <v-btn to="/" text v-if="!$vuetify.breakpoint.xsOnly">
        <span class="mr-2">Home</span>
        <v-icon>mdi-home</v-icon>
      </v-btn>
      <v-btn to="/points" text v-if="!$vuetify.breakpoint.xsOnly">
        <span class="mr-2">Points</span>
        <v-icon>mdi-table-check</v-icon>
      </v-btn>
      <v-btn
        to="/admin"
        text
        v-if="
          !$vuetify.breakpoint.xsOnly &&
          $store.state.user &&
          $store.state.user.uid == '6P9vH41jkINrqfe6kGfxRj8tpUu1'
        "
      >
        <span class="mr-2">Admin</span>
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <v-btn
        @click="signout"
        text
        v-if="!$vuetify.breakpoint.xsOnly && $store.state.user"
      >
        <span class="mr-2">Sign Out</span>
        <v-icon>mdi-logout</v-icon>
      </v-btn>
      <v-btn
        @click="googleSignIn"
        text
        v-if="!$vuetify.breakpoint.xsOnly && !$store.state.user"
      >
        <span class="mr-2">Sign In</span>
        <v-icon>mdi-login</v-icon>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      fixed
      temporary
      dark
      right
      clipped
      src="https://cdn.vuetifyjs.com/images/backgrounds/bg-2.jpg"
      width="50%"
      v-if="!$store.state.loading"
    >
      <v-list nav dense>
        <v-list-item-group v-model="group" active-class="text--accent-4">
          <v-list-item link to="/">
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>HOME</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link to="/points">
            <v-list-item-icon>
              <v-icon>mdi-table-check</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>POINTS</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click="themeSwitch()">
            <v-list-item-icon>
              <v-icon>mdi-brightness-4</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-if="$vuetify.theme.dark"
                >LIGHT MODE</v-list-item-title
              >
              <v-list-item-title v-if="!$vuetify.theme.dark"
                >DARK MODE</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            link
            to="/admin"
            v-if="
              $store.state.user &&
              $store.state.user.uid == '6P9vH41jkINrqfe6kGfxRj8tpUu1'
            "
          >
            <v-list-item-icon>
              <v-icon>mdi-plus</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>ADMIN</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link @click="signout" v-if="$store.state.user">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>SIGN OUT</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link @click="googleSignIn" v-if="!$store.state.user">
            <v-list-item-icon>
              <v-icon>mdi-login</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>SIGN IN</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <br v-if="!$vuetify.breakpoint.xsOnly" />

      <v-row v-if="$store.state.loading">
        <v-col :span="12" class="text-center">
          <v-progress-circular
            indeterminate
            color="primary"
            :size="200"
            :width="7"
          ></v-progress-circular>
        </v-col>
      </v-row>

      <router-view v-else />
    </v-main>
  </v-app>
</template>

<style>
/* .v-main {
  margin-top: 20px;
} */
.v-card__text,
.v-data-table {
  font-family: "Lexend", sans-serif;
}
.v-card__title {
  font-family: "Roboto", sans-serif;
}
.reg_txt {
  text-decoration: none !important;
}
</style>

<script>
import firebase from "firebase/compat/app";

export default {
  name: "App",

  components: {},

  mounted() {
    this.$vuetify.theme.dark = true;
  },

  methods: {
    googleSignIn: function () {
      let provider = new firebase.auth.GoogleAuthProvider();
      firebase
        .auth()
        .signInWithPopup(provider)
        .then((result) => {
          // let token = result.credential.accessToken;
          let user = result.user;
          this.$store.commit("setUser", user);

          // console.log(token); // Token
          // console.log(user); // User that was authenticated
        })
        .catch((err) => {
          console.log(err); // This will give you all the information needed to further debug any errors
        });
    },

    signout() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          alert("Successfully signed out.");
        });
    },
    themeSwitch() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.dark = this.$vuetify.theme.dark + "";
    },
  },

  data: () => ({
    drawer: false,
    group: null,
  }),

  watch: {
    group() {
      this.drawer = false;
    },
  },
};
</script>
