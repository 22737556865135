import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    conversion: [],
    general: [],
    one_off: [],
    points: [],
    sprint: 0,
    user: null,
    loading: true,
  },
  mutations: {
    setTable(state, context) {
      state[context.table] = context.data;
    },
    setLoading(state, context) {
      state.loading = context;
    },
    setUser(state, context) {
      // console.log("Setting user state");
      // console.log(context);
      state.user = context;
    },
  },
  actions: {},
  modules: {},
});
