import Vue from "vue";

Vue.mixin({
  methods: {
    async getTable(table, orderBy) {
      if (this.$store.state[table].length) {
        return;
      }

      let temp = [];
      let querySnapshot = await this.$db
        .collection(table)
        .orderBy(orderBy, "asc")
        .limit(999)
        .get();
      querySnapshot.forEach((doc) => {
        temp.push({
          ...doc.data(),
          id: doc.id,
        });
      });
      if (temp.length) {
        this.$store.commit("setTable", { table: table, data: temp });
        return;
      }
    },
  },
});
