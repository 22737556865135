import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
// eslint-disable-next-line no-unused-vars
import tableCache from "./mixins/tableCache";
import firebaseInit from "./firebase";
import firebase from "firebase/compat/app";

Vue.prototype.$db = firebaseInit();

Vue.config.productionTip = false;

store.commit("setLoading", true);
firebase.auth().onAuthStateChanged((user) => {
  if (user) {
    // console.log("User is logged in");
    store.commit("setUser", user);
    store.commit("setLoading", false);
  } else {
    // console.log("User is not logged in");
    store.commit("setUser", null);
    store.commit("setLoading", false);
  }
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
